body {
    background-size: cover;
    background-position: center;
}

.chess-app {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.player-name {
    position: absolute;
    top: 0;
    background-color: #230c01;
    color: #d9b996;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    z-index: 1;
}

#player-name1 {
    top: 7%;
}

#player-name2 {
    top: 52rem;
}

.flashing-turn {
    animation: flashing 2s;
    animation-iteration-count: 5;
}

@keyframes flashing {
    0% {
        background-color: #230c01;
    }
    50% {
        background-color: #883504;
        font-size: 1.6rem;
    }
    100% {
        background-color: #230c01;
    }
}
