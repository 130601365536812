/* Styling for the in-game promotion menu (when a player's pawn reaches the far end of the board) */
.promotion-modal {
    background-color: #230c01;
    box-shadow: 0 0 10px 5px #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    color: #d9b996;
    padding: 50px;
    opacity: 0.95;
    font-family: Georgia, "Times New Roman", Times, serif;
}

.promotion-modal h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.promotion-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 75%;
}
