/* Styling for coffee mug image on chess board table */

.mug-image {
    transform: rotateZ(5deg);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 300px;
    margin-right: 5%;
    padding: 0;
    transform-origin: top left;
    -webkit-user-drag: none;
    /* background shadow and bottom shadow */
    filter: drop-shadow(-20px -15px 5px rgba(0, 0, 0, 0.5))
        drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.6));
    -webkit-box-reflect: below -80px
        linear-gradient(transparent, transparent 50%, rgba(0, 0, 0, 0.4) 100%);
}

#mug-steam {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 48vh;
    margin-right: 10vw;
    width: 50px;
    height: 100px;
    transform: translateX(-50%);
    z-index: 1;
}

#mug-steam::before,
#mug-steam::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    opacity: 0.2;
    filter: blur(12px);
    animation: rise 8s infinite cubic-bezier(0.42, 0, 0.58, 1);
}

#mug-steam::after {
    width: 70px;
    height: 40px;
    opacity: 0.3;
    animation-delay: 2.5s;
}

@keyframes rise {
    0% {
        transform: translate(-50%, 0) scale(0.7) rotate(0deg);
        opacity: 0.05;
    }
    15% {
        transform: translate(-50%, -20px) scale(0.8) rotate(2deg);
        opacity: 0.2;
    }
    35% {
        transform: translate(-50%, -60px) scale(1) rotate(-1deg);
        opacity: 0.4;
    }
    65% {
        transform: translate(-50%, -100px) scale(1.2) rotate(3deg);
        opacity: 0.5;
    }
    85% {
        transform: translate(-50%, -130px) scale(1.1) rotate(-2deg);
        opacity: 0.2;
    }
    100% {
        transform: translate(-50%, -170px) scale(1.3) rotate(0deg);
        opacity: 0;
    }
}

@media screen and (max-width: 1300px) {
    .mug-image {
        margin-right: 1%;
        transform: rotateZ(5deg);
    }

    #mug-steam {
        margin-top: 350px;
        margin-right: 10%;
    }
}

@media screen and (max-width: 1200px) {
    .mug-image {
        display: none;
    }
    #mug-steam {
        display: none;
    }
}
