/* Styling for the in-game menu on the top right of the screen (Home, Restart, AI-difficulty) */

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 20px;
    right: 20px;
    position: fixed;
    gap: 20px;
}

/* Icons in the menu */
.menu-icon {
    color: #d9b996;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    font-size: 3rem;
}
.menu-icon:hover {
    color: #b2977a;
}

.menu-icon:focus {
    background-color: red;
}

.menu-icon.restart:hover {
    transform: rotate(180deg);
}

.menu-icon.restart {
    transition: transform 0.2s;
}

.menu-button {
    background-color: transparent;
    border: none;
}

/* AI difficulty dropdown select */
#difficulty > select {
    border: none;
    background-color: #d9b996;
    color: #230c01;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: Georgia, "Times New Roman", Times, serif;
}

#difficulty > select.focused-button {
    background-color: blue;
}


#difficulty > select:hover {
    background-color: #b2977a;
}
#difficulty {
    color: #d9b996;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Responsive media queries */
@media screen and (max-width: 768px) {
    .menu-icon {
        font-size: 2.5rem;
    }
    #difficulty > select {
        font-size: 1rem;
    }
}
