/* Styling for the menu that appears when joining a multiplayer game */

.join-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.join-game-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    width: 90%;
}

.join-game-form input {
    width: 100%;
    padding: 0.5rem;
    color: 1px solid var(--primary-secondary-color);
    border-radius: 0.5rem;
    border: none;
    font-size: 1rem;
    box-sizing: border-box;
    background-color: var(--primary-secondary-color);
}

h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-color);
}

.join-game-form label {
    width: 100%;
    font-size: 1rem;
    color: var(--primary-color);
}

.join-game-form button {
    padding: 0.5rem;
    font-size: 1.5rem;
}

@media screen and (min-width: 1000px) {
    .join-game-form {
        width: 30%;
    }
}

@media screen and (min-width: 768px) {
    .join-game-form {
        width: 50%;
    }
}
