/* Styling for card deck image on chess board table */

.cards {
    transform: rotateX(30deg);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin-top: 400px;
    margin-left: 5%;
    transform-origin: top left;
    filter: drop-shadow(-25px -15px 5px rgba(0, 0, 0, 0.4))
        drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.6));
    -webkit-box-reflect: below -55px
        linear-gradient(transparent, transparent 70%, rgba(0, 0, 0, 0.6) 100%);
}

.cards-image {
    -webkit-user-drag: none;
}

@media screen and (max-width: 1200px) {
    .cards {
        display: none;
    }
}
