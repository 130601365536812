/* Styling for the main menu */
.start-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: var(--primary-color);
    padding: 4rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.start-menu > h2 {
    font-size: 3rem;
}

.focused-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .start-menu {
        padding: 2rem;
    }
    .start-menu > h2 {
        font-size: 2rem;
    }
}
