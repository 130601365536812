#current-turn {
    position: absolute;
    top: 0.5rem;
    background-color: #230c01;
    color: #d9b996;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    z-index: 1;
}
