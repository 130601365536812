/* Styling for the menu that appears after clicking 'Multiplayer' on main menu */

.multiplayer-menu {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: var(--primary-color);
    font-size: 2rem;
    padding: 4rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

#game-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

#icon-cancel {
    color: rgba(255, 0, 0, 1);
}

#icon-cancel:hover {
    cursor: pointer;
}

.multiplayer-menu h1 {
    font-size: 3rem;
}

@media screen and (max-width: 768px) {
    .multiplayer-menu {
        padding: 2rem;
    }
    .multiplayer-menu > h1 {
        font-size: 3rem;
    }
}
