/* Styling for chess piece image */

.piece {
    position: relative;
    pointer-events: none;
}

.chess-piece-image {
    filter: drop-shadow(-0.75vw -0.5vw 0.05vw rgba(0, 0, 0, 0.2));
    position: absolute;
}
