/* Styling for the red flash animation that occurs under the king piece under a check */

@keyframes king-flash {
    0% {
        background-color: #51000066;
    }
    50% {
        background-color: #c9020266;
    }
    100% {
        background-color: #51000066;
    }
}
