/* Styling for the menu that pops up when a game ends */

.game-over {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #230c01;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 2rem;
    color: #d9b996;
    z-index: 10;
    opacity: 0.8;
}

#buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 700px) {
    .game-over {
        padding: 0.5rem;
    }
    .game-over h1 {
        font-size: 2rem;
    }
    .game-over h2 {
        font-size: 1rem;
    }
}
