/* Styling for candle + candle flame on chess board table */

.candle {
    transform: perspective(1000px) rotateX(30deg);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-left: 5%;
    padding: 0;
    transform-origin: top left;
    filter: drop-shadow(-20px -15px 5px rgba(0, 0, 0, 0.5))
        drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.6));
    -webkit-box-reflect: below -70px
        linear-gradient(transparent, transparent 35%, rgba(0, 0, 0, 0.4) 100%);
}

.candle-image {
    -webkit-user-drag: none;
}

@media screen and (max-width: 1200px) {
    .candle {
        display: none;
    }
    .candle-image {
        display: none;
    }
    .flame-image {
        display: none;
    }
}

.flame-image {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
    animation: flicker 10s infinite;
    position: absolute;
    bottom: 170px;
    left: 49%;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
    -webkit-box-reflect: below 20px
        linear-gradient(transparent, rgba(0, 0, 0, 0.2) 100%);
}

@keyframes flicker {
    0% {
        opacity: 0.7;
        transform: scale(0.9) translateY(0) rotate(7deg);
    }
    10% {
        opacity: 1.2;
        transform: scale(1.2) translateY(-2px) rotate(0deg);
    }
    20% {
        opacity: 0.8;
        transform: scale(0.7) translateY(-10px) rotate(-5deg);
    }
    30% {
        opacity: 1.5;
        transform: scale(1) translateY(-3px) rotate(0deg);
    }
    40% {
        opacity: 0.9;
        transform: scale(0.8) translateY(-5px) rotate(3deg);
    }
    50% {
        opacity: 1.3;
        transform: scale(1.1) translateY(-1px) rotate(0deg);
    }
    60% {
        opacity: 0.7;
        transform: scale(0.9) translateY(-7px) rotate(-4deg);
    }
    70% {
        opacity: 1.2;
        transform: scale(1.2) translateY(-2px) rotate(0deg);
    }
    80% {
        opacity: 0.8;
        transform: scale(0.7) translateY(-10px) rotate(5deg);
    }
    90% {
        opacity: 1.5;
        transform: scale(1) translateY(-3px) rotate(0deg);
    }
    100% {
        opacity: 0.7;
        transform: scale(0.9) translateY(0) rotate(7deg);
    }
}
