/* Styling for menu that appears after a player connects to a multiplayer game and is waiting for the other player to join */

.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #dfbb94;
    font-size: 2rem;
}

.loading-screen button {
    max-width: 50%;
}

#elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    #elements h2 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 1000px) {
    #elements {
        width: fit-content;
    }
}

/* This is an animation from CodePen to create a loading spinner */
.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow:
        0 -18px 0 0 #dfbb94,
        12.727926px -12.727926px 0 0 #dfbb94,
        18px 0 0 0 #dfbb94,
        12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
        0 18px 0 0 rgba(152, 128, 255, 0),
        -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
        -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {
    0%,
    100% {
        box-shadow:
            0 -18px 0 0 #dfbb94,
            12.727926px -12.727926px 0 0 #dfbb94,
            18px 0 0 0 #dfbb94,
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
        box-shadow:
            0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.727926px -12.727926px 0 0 #dfbb94,
            18px 0 0 0 #dfbb94,
            12.727926px 12.727926px 0 0 #dfbb94,
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
        box-shadow:
            0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 0 #dfbb94,
            12.727926px 12.727926px 0 0 #dfbb94,
            0 18px 0 0 #dfbb94,
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
        box-shadow:
            0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 0 #dfbb94,
            0 18px 0 0 #dfbb94,
            -12.727926px 12.727926px 0 0 #dfbb94,
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
        box-shadow:
            0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 0 #dfbb94,
            -12.727926px 12.727926px 0 0 #dfbb94,
            -18px 0 0 0 #dfbb94,
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
        box-shadow:
            0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 0 #dfbb94,
            -18px 0 0 0 #dfbb94,
            -12.727926px -12.727926px 0 0 #dfbb94;
    }
    75% {
        box-shadow:
            0 -18px 0 0 #dfbb94,
            12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 0 #dfbb94,
            -12.727926px -12.727926px 0 0 #dfbb94;
    }
    87.5% {
        box-shadow:
            0 -18px 0 0 #dfbb94,
            12.727926px -12.727926px 0 0 #dfbb94,
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 0 #dfbb94;
    }
}
