/* Styling for WaitingRoom menu (the menu with the QR code) */

.waiting-room {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #dfbb94;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 60%;
}
.waiting-room h1 {
    font-size: 3rem;
    margin-bottom: 0;
}

.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    gap: 1rem;
}

.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 3rem;
}

.waiting-room h2 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: 0;
}

.player-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.player {
    background-color: var(--secondary-color);
    background-image: linear-gradient(0deg, #50230f 0%, #441b08 100%);
    padding: 1rem;
    border-radius: 15px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.7);
    min-width: 200px;
    text-align: center;
}
#qr-code {
    border-radius: 5px;
    box-shadow: 0 0 2px 5px #000000;
}

#game-id {
    font-size: 1rem;
    font-weight: bold;
}

#id-wrapper {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #230c01;
    max-width: 75%;
}

#id-wrapper p {
    margin: 0;
}

.icon:hover {
    cursor: pointer;
    color: #b2977a;
}

#cancel-button {
    margin-top: 20px;
    background-color: #c44646;
    border: none;
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.5rem;
    box-shadow: 0 0 2px 2px #000000;
    font-family: Georgia, "Times New Roman", Times, serif;
}

.waiting-room p {
    font-size: 1.5rem;
    margin: 0;
}

#cancel-button:hover {
    background-color: #be4242;
    cursor: pointer;
}

/* Make the menu responsive */
@media screen and (min-width: 768px) {
    .waiting-room {
        padding: 1rem;
        max-width: 85%;
    }
    #id-wrapper p {
        font-size: 0.7rem;
    }
    #id-wrapper button {
        font-size: 1.5rem;
    }
    #id-wrapper {
        padding: 1rem;
    }
    #game-id {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1300px) {
    .waiting-room {
        padding: 3rem;
        max-width: 85%;
    }
    #id-wrapper p {
        font-size: 0.7rem;
    }
    #id-wrapper button {
        font-size: 1.5rem;
    }
    #id-wrapper {
        padding: 1rem;
    }
}

/* Animations for the loading dots */
.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #dfbb94;
    color: #dfbb94;
    animation: dot-elastic 2s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #dfbb94;
    color: #dfbb94;
    animation: dot-elastic-before 2s infinite linear;
}
.dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #dfbb94;
    color: #dfbb94;
    animation: dot-elastic-after 2s infinite linear;
}

@keyframes dot-elastic-before {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.5);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes dot-elastic {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes dot-elastic-after {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}
