body {
    margin: 0;
    overflow: hidden;
    font-family: Georgia, "Times New Roman", Times, serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
}

:root {
    --primary-color: #d9b996;
    --tertiary-color: #b2977a;
    --primary-secondary-color: #eac7a3;
    --secondary-color: #441b08;
    --transparent: #000000b3;
}

/* Overlay style for menus */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--transparent);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Button styles for all menus */
.chess-button {
    width: 100%;
    background-color: var(--primary-color);
    font-family: Georgia, "Times New Roman", Times, serif;
    color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    font-size: 2rem;
    padding: 1rem;
    box-shadow: -5px 5px 10px var(--transparent);
    transition: background-color 0.2s;
}

.chess-button:hover,
.chess-button:focus {
    background-color: var(--secondary-color);
    background-image: linear-gradient(0deg, #50230f 0%, #441b08 100%);
    color: var(--primary-color);
    cursor: pointer;
}
